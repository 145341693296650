// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";
// import smoothscroll from "smoothscroll-polyfill";
// import { HelmetProvider } from "react-helmet-async";
// const root = ReactDOM.createRoot(document.getElementById("root"));
// smoothscroll.polyfill();
// root.render(
//   <React.StrictMode>
//     <HelmetProvider>
//       <App />
//     </HelmetProvider>
//   </React.StrictMode>
// );
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import smoothscroll from "smoothscroll-polyfill";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
smoothscroll.polyfill();

root.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* <BrowserRouter> */}
      <App />
      {/* </BrowserRouter> */}
    </HelmetProvider>
  </React.StrictMode>
);
